export const mainHeader = 'Kaseton reklamowy z winylu';
export const pageDescription = 'Postaw na sprawdzoną i niezawodną reklamę swojej firmy. Zamów podświetlany kaseton winylowy w dowolnym rozmiarze wraz z czujnikiem zmierzchu. Zapraszamy!';

export const cofferInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**<span class="orange">Czas realizacji</span>**
      \n 5 - 10 dni roboczych. Do czasu realizacji nie jest wliczany czas ewentualnego umówienia terminu montażu.`,
    },
  ],
  rightColumn: [
    {
      article: `## ${mainHeader}
      \n Wykorzystywany przy bardzo dużych wymiarach. Możliwość wydruku bez łączeń nawet do 49 metrów długości. Lico wykonane z materiałowego dedykowanego winylu 
      napiętego na aluminiowej ramie. Podświetlenie rozproszone na całej grafice. Możliwość pomalowania ramy na dowolny kolor z palety RAL lub oklejenie barwioną folią ORACAL..
      `,
    },
    {
      article: `**Specyfikacja**
      \n * Lico: Winyl
      \n * Boki: Rama aluminiowa lakierowana proszkowo 
      \n * Grubość kasetonu: 10cm
      \n * Plecy: Płyta PCV 5mm
      \n * Zasilanie i LED: Mean Well / Refond
      `,
    },
    {
      article: `**Opcje dodatkowe**
      \n * Czujnik zmierzchu:  \n Automatycznie włącza i wyłącza podświetlenie w zależności od pory dnia; 
      możliwość regulacji czułości czujnika - **<span class="orange">100 zł</span>**
      `,
    },
    {
      article: `**Inny wymiar?**
      \n Wycena indywidualna.
      `,
    },
    {
      article: `**Informacje potrzebne przy zamówieniu**
      \n * wybrany rozmiar
      \n * kolor obudowy <a href="https://banerbunny.pl/szablony/kasetony/kolory-obudowy-wzornik.pdf" target="_blank" download>pobierz plik</a>
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">wymiary</span>**
      \n Najczęściej zamawiane wymiary kasetony to:
      \n * kaseton 400 x 60 cm
      \n * kaseton 600 x 60 cm
      \n * kaseton 800 x 60 cm
      \n * kaseton 400 x 80 cm
      \n * kaseton 600 x 80 cm
      \n * kaseton 800 x 80 cm
      \n * kaseton 1000 x 60 cm
      \n * kaseton 1000 x 80 cm
      `,
    },
  ],
  wide: [
    {
      article: `**<span class="orange">Koszt wysyłki</span>**
      \n * Kurier standard (przy kasetonach gdzie suma szerokości i wysokości nie przekracza 200cm) - 50 zł
      \n * Kurier gabaryt (przy kasetonach gdzie suma szerokości i wysokości przekracza 200cm) - 100 zł
      \n * Dowóz osobisty na terenie Warszawy i okolic - 100 zł
      \n * Dowóz osobisty w całej Polsce - wycena indywidualna
      \n * Podane ceny są cenami netto.
      `,
    },
    {
      slogan: '**<span class="center"><span class="orange">Tanie kasetony</span> - Twoja reklama święcąca nad sklepem</span>**',
    },
  ],
};
